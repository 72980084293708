<template>
  <panel class="page-footer">
    <a href="/impressum">Impressum</a>
    <a href="/datenschutz">Datenschutz</a>
  </panel>
</template>

<script>
import Panel from './panel';
export default {
  name: 'page-footer',
  components: {Panel},
};
</script>

<style scoped lang="scss">
@import "src/styles/variables";

.page-footer {
  background-color: $colorPrimary;
  color: $colorWhite;
  display: flex;
  justify-content: space-between;
  padding: 12px 4px;
}
</style>
