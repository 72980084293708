<template>
  <div class="text-block">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'text-block',
};
</script>

<style scoped lang="scss">
.text-block {
  margin-bottom: 16px;
}
</style>
