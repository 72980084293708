<template>
    <component :width="width" :height="height" :fill="color" :is="name"></component>
</template>

<script>

import Foot from '../icons/foot';
import Logo from '../icons/logo';

export default {
  name: 'svgIcon',
  props: {
    name: {
      type: String,
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
  components: {
    Foot,
    Logo
  },
  computed: {
    path() {
      const icons = {
        foot: Foot,
        logo: Logo,
      };

      return icons[this.name];
    },
  },
};
</script>
