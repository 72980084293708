<template>
  <div class="start">
    <h2>Wir sind für Sie da!</h2>

    <p>
      Aus aktuellen Anlass, möchte wir Sie darauf hinweisen, dass Termine rechtzeitig abzusagen
      sind,
      wenn Sie Corona Symptome aufweisen, oder der Verdacht besteht, dass sie mit Corona
      Kontaktpersonen in Berührung kamen, oder sich in Risikogebieten aufgehalten haben.
    </p>

    <p>
      Auch bei Krankheitssymptomen, wie Halsschmerzen, Heiserkeit, Fieber, Kopf- und
      Gliederschmerzen
      oder Durchfall, bitten wir Sie herzlichst auf Ihren vereinbarten Termin zu verzichten, um
      andere
      Patienten und Mitarbeiter zu schützen.Bitte nehmen Sie Kontakt zu uns auf.
    </p>

    <p class="text-info">
      Das Tragen von einer FFP 2 Maske ist in unserer Praxis Pflicht.
    </p>

    <p>
      Sollten Sie über eine Mund- und Nasenmaskenbefreiung verfügen, bitten wir Sie, sich
      telefonisch bei
      uns zu melden.
    </p>

    <p>
      Handwaschung - und ausreichende Händedesinfektionsmöglichkeiten stehen Ihnen
      selbstverständlich zur Verfügung.
    </p>
  </div>
</template>

<script>
export default {
  name: 'start',
};
</script>

<style scoped>
  .text-info {
    font-weight: bold;
  }
</style>
