<template>
  <div>
    <start></start>
    <panel><separator></separator></panel>

    <what-is></what-is>
    <panel><separator></separator></panel>

    <services></services>
    <panel><separator></separator></panel>

    <location></location>
  </div>
</template>

<script>
import TextBlock from '../components/text-block';
import Separator from '../components/separator';
import Panel from '../components/panel';
import Start from '../components/sections/start';
import WhatIs from '../components/sections/whatIs';
import Services from '../components/sections/services';
import Location from '../components/sections/location';

export default {
  name: 'home',
  components: {Location, Services, WhatIs, Start, Panel, Separator, TextBlock},
};
</script>
