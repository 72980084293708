<template>
  <div class="services">
    <h2>Unsere Leistungen</h2>

    <ul>
      <li>Behandlungen an Patienten mit Diabetes, Rheuma, Gicht und Neuropathie</li>
      <li>Podologische Komplexbehandlung( auf Heilmittelverordnung vom zugewiesenen Arzt)</li>
      <li>Behandlungen an Nagelpilzerkrankten Nägeln, sowie unguis incarnatus (eingewachsenen
        Nägeln)
        und unguis convolutus (eingerollten Nägeln), durch korrekten Nagelschnitt ggf.
        Nagelfalztamponaden oder auch Nagelkorrekturspangen( die ist mit einem Rezept vom Arzt
        möglich)
      </li>
      <li>Medizinische Nagelprothetik nach Verletzungen im Nagelbereich</li>
      <li>Behandlungen von Hornschwielen, Schrunden und Rhagaden</li>
      <li>Abtragung von clavi (Hühneraugen) und medizinischen Versorgung von Veruccea (Warzen), auch
        die
        Versorgung und medizinisch Beratung von Hautpilzerkrankungen
      </li>
      <li>Begleitende Therapie nach chirugischen Eingriffen nach Anweisung vom Arzt</li>
      <li>Wundrandabtragungen auf Anweisung vom Arzt, mit Rezept</li>
      <li>Druck- und Reibungsversorgung mithilfe von Orthosen und Klebepolstern (u.a. Fleecy web,
        Moleskin)
      </li>
      <li>Analyse der Schuh- und Einlagenversorgung</li>
      <li>Ausführliche Beratung bei individuellen Problemen</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'services',
};
</script>

<style scoped>

</style>
