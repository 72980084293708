<template>
  <panel class="navigation">
    <a v-for="item in navItems" :href="item.link">{{ item.name }}</a>
  </panel>
</template>

<script>
import Panel from './panel';
export default {
  name: 'navigation',
  components: {Panel},
  data() {
    return {
      navItems: [
        {name: 'Start', link: '/'},
        {name: 'Über uns', link: '/team'},
        {name: 'Unsere Angebot', link: '/angebote'},
        {name: 'Sprechzeiten', link: '/sprechzeiten'},
        {name: 'Kontakt', link: '/kontakt'},
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/variables";

.navigation {
  background-color: $colorSecondary;
  display: flex;
  text-align: center;

  a {
    align-items: center;
    color: $colorWhite;
    display: inline-flex;
    justify-content: center;
    padding: 8px 2px;
    text-decoration: none;
    width: 20%;

    &:hover {
      background-color: #ffffff33;
    }
  }
}
</style>
