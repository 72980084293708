<template>
  <section class="page container">
    <header>
      <page-header></page-header>
    </header>
    <main>
      <div>
        <slot></slot>
      </div>
    </main>
    <footer>
      <page-footer></page-footer>
    </footer>
  </section>
</template>

<script>
import PageHeader from './page-header';
import PageFooter from './page-footer';

export default {
  components: {
    PageFooter,
    PageHeader,
  },
};
</script>

<style lang="scss">
@import "src/styles/variables";

body {
  background-color: $colorGray1;
  color: $colorPrimary;
  font-family: Calibri, Arial, sans-serif;
  height: 100vh;
  margin: 0;
  min-width: 360px;
  overflow-x: hidden;
}

a {
  color: $colorSecondary;
}

div, span, p {
  hyphens: auto;
  word-break: break-word;
}

img {
  height: auto;
  max-width: 100%;
}

table {
  width: 100%;
}

h2 {
  border-bottom: 1px solid $colorPrimary;
}

h3 {
  color: $colorSecondary;
}

hr {
  border: 2px solid $colorSecondary;
}

header {
  grid-area: head;
}

main {
  grid-area: main;
}

footer {
  grid-column: foot;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 99%;

  @media screen and (min-width: 775px) {
    max-width: 750px;
  }
}

.page {
  display: grid;
  height: 100%;
  grid-template:
    [header-left] "head"     [header-right]
    [main-left]   "main" 1fr [main-right]
    [footer-left] "foot"     [footer-right]
     / 1fr;
  width: 100%;
}

.text-info {
  background-color: lighten($colorPrimary, 50);
  border: 1px solid $colorPrimary;
  padding: 4px 8px;
}
</style>
