<template>
  <div class="location">
    <h2>Unsere Praxis</h2>

    <p>FOTO-GALLERIE</p>
    <p>GMAPS KARTE</p>

    <h3>So erreichen Sie uns</h3>
    <h4>Mit öffentlichen Verkehrsmitteln:</h4>

    <p>U Bahnhof Hellersdorf mit der U5</p>
    <p>Tram: M6, 18</p>
    <p>Bus: X54, 195, N5</p>
    <p>
      Laufen Sie über den Alice- Salomonplatz direkt in die Peter- Weiß- Gasse
      Der Aufgang befindet sich auf der rechten Seite.
    </p>

    <h4>Mit dem Auto:</h4>
    <p>
      Über die Quedlinburger Straße oder die Stendaler Straße fahren sie direkt zum Marktplatzcenter
      Hellerdorf
    </p>
    <p>Parkmöglichkeiten gibt es im Center</p>
    <p>
      Der Eingang zu unserer Praxis befindet sich in der Gasse zwischen Alice Salomon Hochschule
      und Marktplatzcenter Hellersdorf.
    </p>
    <p>Ein Zugang vom Center zu uns gibt es nicht.</p>
    <p>Wir freuen uns auf Ihren Besuch.</p>
  </div>
</template>

<script>
export default {
  name: 'location',
};
</script>

<style scoped>

</style>
