<template>
  <layout>
    <component :is="page"></component>
  </layout>
</template>

<script>
import Layout from './components/layout.vue';
import Home from './pages/home';
import Datenschutz from './pages/datenschutz';
import Impressum from './pages/impressum';

export default {
  components: {
    Layout,
    Home,
    Impressum,
    Datenschutz,
  },
  computed: {
    page() {
      return location.pathname.substring(1) || 'home'
    }
  }
};
</script>
