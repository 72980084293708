<template>
  <div class="separator">
    <svg-icon v-for="index in Array(5)" name="foot" width="40" height="20"></svg-icon>
  </div>
</template>

<script>
import SvgIcon from './svg-icon';
export default {
  name: 'separator',
  components: {SvgIcon},
};
</script>

<style scoped lang="scss">
.separator {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
}
</style>
