<template>
  <div>
    <h2>Datenschutzerklärung</h2>

    <h3>1. Datenschutz auf einen Blick</h3>

    <h4>Allgemeine Hinweise</h4>

    <p>
      Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
      personenbezogenen
      Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit
      denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema
      Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
    </p>

    <h4>Datenerfassung auf unserer Website</h4>

    <h5>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h5>

    <p>
      Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
      Kontaktdaten
      können Sie dem Impressum dieser Website entnehmen.
    </p>

    <h5>Wie erfassen wir Ihre Daten?</h5>

    <p>
      Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es
      sich
      z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
    </p>

    <p>
      Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das
      sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
      Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
      betreten.
    </p>

    <h5>Wofür nutzen wir Ihre Daten?</h5>

    <p>
      Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
      gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
    </p>

    <h5>Welche Rechte haben Sie bezüglich Ihrer Daten?</h5>

    <p>
      Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
      gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die
      Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
      Fragen
      zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an
      uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
      zu.
    </p>

    <h4>Analyse-Tools und Tools von Drittanbietern</h4>

    <p>
      Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das
      geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres
      Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
      zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die
      Nichtbenutzung
      bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in der folgenden
      Datenschutzerklärung.
    </p>

    <p>
      Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in
      dieser Datenschutzerklärung informieren.
    </p>

    <h3>2. Allgemeine Hinweise und Pflichtinformationen</h3>

    <h4>Datenschutz</h4>

    <p>
      Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
      behandeln
      Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
      Datenschutzvorschriften sowie dieser Datenschutzerklärung.
    </p>

    <p>
      Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
      Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
      vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen.
      Sie erläutert auch, wie und zu welchem Zweck das geschieht.
    </p>

    <p>
      Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
      E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
      durch
      Dritte ist nicht möglich.
    </p>

    <h4>Hinweis zur verantwortlichen Stelle</h4>

    <p>
      Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
    </p>

    <!--    @todo-->
    <p>
      Podologie Praxis Becker<br/>
      Bianca Becker<br/>
      Peter-Weiss-Gasse 1<br/>
      12627 Berlin
    </p>

    <p>
      Telefon: 030 99283012<br/>
      <!--    @todo-->
      E-Mail: ??? [E-Mail-Adresse]
    </p>

    <p>
      Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam
      mit
      anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen,
      E-Mail-Adressen o. Ä.) entscheidet.
    </p>

    <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>

    <p>
      Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie
      können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
      Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
      Datenverarbeitung bleibt vom Widerruf unberührt.
    </p>

    <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>

    <p>
      Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der
      zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen
      ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz
      hat.
      Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link
      entnommen
      werden: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
    </p>

    <h4>Recht auf Datenübertragbarkeit</h4>

    <p>
      Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
      Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
      maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
      an
      einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
    </p>

    <h4>SSL- bzw. TLS-Verschlüsselung</h4>

    <p>
      Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte,
      wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
      SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die
      Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in
      Ihrer Browserzeile.
    </p>

    <p>
      Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
      übermitteln, nicht von Dritten mitgelesen werden.
    </p>

    <h4>Auskunft, Sperrung, Löschung</h4>

    <p>
      Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
      unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
      Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
      oder
      Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können
      Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
    </p>

    <h4>Widerspruch gegen Werbe-Mails</h4>

    <p>
      Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung
      von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
      widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
      Falle
      der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
    </p>

    <h3>3. Datenerfassung auf unserer Website</h3>

    <h4>Cookies</h4>

    <p>
      Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner
      keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
      nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die
      auf
      Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
    </p>

    <p>
      Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden
      nach
      Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert
      bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
      wiederzuerkennen.
    </p>

    <p>
      Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden
      und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder
      generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser
      aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website
      eingeschränkt sein.
    </p>

    <p>
      Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur
      Bereitstellung
      bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
      werden
      auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein
      berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und
      optimierten
      Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres
      Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert
      behandelt.
    </p>

    <p>Diese Webseite verwendet Cookies.</p>

    <p>
      Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale
      Medien
      anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir
      Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien,
      Werbung
      und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren
      Daten
      zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste
      gesammelt haben.
    </p>

    <p>Weitere Informationen finden Sie in unserer Datenschutzerklärung</p>

    <p>
      Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die Benutzererfahrung
      effizienter zu gestalten.
    </p>

    <p>
      Laut Gesetz können wir Cookies auf Ihrem Gerät speichern, wenn diese für den Betrieb dieser
      Seite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir Ihre Erlaubnis.
    </p>

    <p>
      Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von Drittparteien
      platziert, die auf unseren Seiten erscheinen.
    </p>

    <p>
      Sie können Ihre Einwilligung jederzeit von der Cookie-Erklärung auf unserer Website ändern
      oder
      widerrufen.
    </p>

    <p>
      Erfahren Sie in unserer Datenschutzrichtlinie mehr darüber, wer wir sind, wie Sie uns
      kontaktieren können und wie wir personenbezogene Daten verarbeiten.
    </p>

    <p>
      Bitte geben Sie Ihre Einwilligungs-ID und das Datum an, wenn Sie uns bezüglich Ihrer
      Einwilligung kontaktieren.
    </p>

    <p>
      <!--      @todo-->
      Ihre Einwilligung trifft auf die folgenden Domains zu: www.???[Domain-Name].de
    </p>

    <p>
      Die Cookie-Erklärung wurde das letzte Mal am 19.04.21 von aktualisiert:
    </p>

    <div class="box">
      <p>Notwendig (1)</p>

      <p>
        Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen
        wie
        Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite
        kann
        ohne diese Cookies nicht richtig funktionieren.
      </p>

      <table>
        <tbody>
          <tr>
            <th colspan="2">CookieConsent</th>
          </tr>

          <tr>
            <td width="60">Anbieter</td>
            <!--          @todo-->
            <td>???[Name des Service Anbieters]</td>
          </tr>

          <tr>
            <td>Zweck</td>
            <td>
              Speichert den Zustimmungsstatus des Benutzers für Cookies auf der aktuellen Domäne.
            </td>
          </tr>

          <tr>
            <td>Ablauf</td>
            <td>1 Jahr</td>
          </tr>

          <tr>
            <td>Typ</td>
            <td>HTTP Cookie</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4>Server-Log-Dateien</h4>

    <p>
      Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
      Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
    </p>

    <ul>
      <li>Browsertyp und Browserversion</li>
      <li>verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
      <li>Hostname des zugreifenden Rechners</li>
      <li>Uhrzeit der Serveranfrage</li>
      <li>IP-Adresse</li>
    </ul>

    <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>

    <p>
      Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von
      Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
    </p>
  </div>
</template>

<script>
export default {
  name: 'datenschutz',
};
</script>

<style scoped lang="scss">
@import "src/styles/variables";

.box {
  border: 1px solid $colorBlack;
  padding: 8px;
}

th {
  background-color: $colorPrimary;
  border-bottom: 1px solid $colorPrimary;
  color: $colorWhite;
}

th, td {
  padding: 2px;
}
</style>
