<template>
  <div class="page-header">
    <panel class="contact-line-panel">&nbsp;</panel>

    <div class="brand">
      <a class="logo" href="/">
        <svg-icon name="logo"></svg-icon>
      </a>

      <div>
        <h1 class="name">Podologie Praxis Becker</h1>

        <div class="info-wrapper">
          <div class="contact">
            <div>Peter-Weiss-Gasse 1</div>
            <div>12627 Berlin</div>
            <div>Tel.: <a href="tel:+493099283012">030/99283012</a></div>
            <div>Fax: 030/99281725</div>
            <div>
              Email:
              <a href="mailto:info@podologie-hellemitte.de?subject=Anfrage">
                info@podologie-hellemitte.de
              </a>
            </div>
          </div>

          <div class="opening">
            <div>Unsere Kernöffnungzeiten:</div>
            <div class="time">
              <div class="days">
                Mo. - Do.:<br/>
                Fr.:
              </div>
              <div>
                8.00 - 18.00 Uhr<br/>
                8.00 - 15.30 Uhr
              </div>
            </div>
            <div>
              <b>Zusätzliche Wunschtermine</b> auch gerne <b>nach telefonischer Vereinbarung</b>.
            </div>
          </div>
        </div>
      </div>
    </div>
    <navigation></navigation>
  </div>
</template>

<script>
import SvgIcon from './svg-icon';
import Navigation from './navigation';
import Panel from './panel';

export default {
  name: 'pageHeader',
  components: {Panel, Navigation, SvgIcon},
};
</script>

<style scoped lang="scss">
@import "src/styles/variables";

.page-header {
  .contact-line-panel {
    background-color: $colorPrimary;
    color: $colorWhite;

    ul {
      display: flex;
      gap: 4px;
      justify-content: space-between;
      list-style-type: none;
      margin: 0 auto;
      padding: 8px 0;
    }
  }

  .brand {
    align-items: center;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    min-height: 200px;

    .logo {
      background-color: $colorSecondary;
      border-radius: 100%;
      display: none;

      @media screen and (min-width: 730px) {
        display: inline-block;
      }

      svg {
        height: 150px;
        width: 150px;

        ::v-deep.text {
          fill: $colorPrimary;
        }

        ::v-deep.feet {
          fill: $colorWhite;
        }
      }
    }

    .name {
      font-size: 32px;
      font-weight: bold;
      font-variant: small-caps;
    }

    .info-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 16px;

      .contact, .opening {
        min-width: 260px;
        width: 49%;
      }

      .opening {
        .time {
          display: flex;
          gap: 4px;

          .days {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
